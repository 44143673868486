
import { Component, Vue, Watch } from "vue-property-decorator";
import { Toast, Dialog } from "vant";
import { ArticleService } from "src/services";
import tabbar from "src/components/tabbar";
import { tools } from "src/utils";
import { Payment_Type } from "src/enums";
import router from "src/router";

@Component({
  components: {
    tabbar,
  },
})
export default class HealthProductArticle extends Vue {
  /**
   * ids
   * @private
   * @returns Array<number>
   */
  private get ids() {
    let ids = [];
    if (localStorage.getItem("articleList")) {
      ids = JSON.parse(localStorage.getItem("articleList"));
    }
    return (this.$route.params && this.$route.params.ids) || ids;
  }

  /**
   * 查看健康短文详情
   * @private
   * @returns void
   */
  protected onArticleDetail(id): void {
    this.$router.push({ name: "health-product-article-detail", query: { id } });
  }

  /**
   * 获取订单列表
   */
  protected async loadData(parameter: any): Promise<any> {
    try {
      let { content: res } = await ArticleService.instance.getHealthArticlePageList(Object.assign({ articleIds: this.ids }, parameter));

      return res.data;
    } catch (err) {
      Toast(err);
    }
  }
}
